<template>
  <div class="perssion">
    <div v-if="type == 1">

      <h1>隐私协议</h1>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;本次版本更新时间：2024年【5】月【1】日</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;本次版本生效时间：2024年【4】月【4】日</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;欢迎使用嗨数产品！</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;引言</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;嗨数（以下统称“我们”）非常注重保护所有服务用户（以下统称“您”）的个人信息及隐私。我们深知个人信息对您的重要性，将以高度勤勉、审慎义务对待这些信息，按照法律法规和业界成熟的标准，采取相应的安全措施来保护您的个人信息。请在使用我们的产品（或）服务前，仔细阅读并了解《嗨数隐私政策》的条款（尤其是加粗、划线的内容），以保障您的权益。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;为了给您提供更准确、更优质的服务，我们会按照本《嗨数用户隐私政策》（以下统称“本隐私政策”）的规定使用和披露您的个人信息。除本隐私政策另有规定外，在未征得您事先许可的情况下，我们不会将您的个人信息对外披露或向第三方提供。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;本隐私政策适用于嗨数网站hishowyun.com/login；“嗨数”APP；“嗨数数据选品”微信公众号；“嗨数跨境数据”抖音小程序（以下统称“嗨数平台”）。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;随着嗨数平台提供服务范围的扩大，本隐私政策的内容可由我们随时更新，但任何更新均会以适合的方式向您通知，包括但不限于平台公告、短信、邮件。除非法律另行要求，更新后的隐私政策一经通知，即有效代替原来的隐私政策。如您继续使用嗨数平台各项产品和（或）服务的，视为您同意并接受嗨数平台更新后的本隐私政策。如您拒绝同意变更后的本隐私政策，您应立即停止访问和使用嗨数平台。如发生有关争议，以嗨数平台最新的相关协议和规则为准。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;本隐私政策中与您的权益密切相关的条款采用加粗、下划线的方式提示您注意阅读。在您向嗨数平台提供任何属于敏感信息的个人信息前，请您审慎考虑该等提供是否恰当，并且同意您的个人敏感信息可按本隐私政策所述的目的和方式进行处理。我们会在得到您的同意后收集和使用您的个人敏感信息以实现与嗨数业务相关的功能，并允许您对这些个人敏感信息的收集与使用做出不同意的选择，拒绝提供这些信息仅会使您无法使用嗨数平台的相关特定功能，但不影响您正常使用嗨数的其他功能。 </p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;本隐私政策属于《嗨数用户服务协议》不可分割的一部分。我们希望通过本隐私政策向您清晰地介绍在使用我们的产品和（或）服务时，我们如何收集、处理您的个人信息，并提供访问、更正、删除、保护这些信息的方式。如您对本隐私政策有任何疑问或您在使用我们提供的产品和（或）服务时，个人信息受到了侵扰，或不同意协议中的任何条款，您应立即停止访问和使用嗨数平台。当您使用嗨数平台提供的任一产品和（或）服务时，即意味着同意我们按照本隐私条款政策收集、使用、存储和共享您的个人信息。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;关于我们</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;嗨数平台的经营者为浙江台州嗨数科技有限公司及其关联公司。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;注册地址为浙江省台州市温岭市城东街道万昌中路1333号创业大厦2幢2701室。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;《嗨数隐私政策》将帮您了解以下信息：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;一、我们如何收集和使用您的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;二、我们如何存储和保护您的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;三、我们如何共享、转让、公开披露您的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;四、您如何管理您的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;五、我们如何处理未成年人的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;六、本隐私政策的更新和通知</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;七、如何联系我们</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;一、我们如何收集和使用您的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;在您使用我们的产品和（或）服务的过程中，我们会按照如下方式收集、使用您在使用我们的产品和（或）服务时主动提供或因为使用我们的产品和（或）服务而产生的信息：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.账号注册、登录</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1您注册并登录嗨数平台账号时，需要按照我们的指引完成一系列注册程序，在此过程中，您需要向我们提供手机号码，用于验证您的身份。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您只有提供真实准确的上述信息，才能成功注册嗨数平台账号并使用嗨数平台的产品及服务的核心功能。如果您选择不提供上述必备信息，或将导致我们无法为您提供该核心产品功能。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.2我们的产品支持您使用第三方平台的账号（微信账号或AppleID）进行注册、登录，如您使用第三方平台的账号注册、登录，我们将根据您的授权获取该第三方账号下的相关信息，包括：微信的昵称、头像、性别和地区，或AppleID包含的个人邮箱。我们承诺，收集上述信息是用于为您提供账号注册及登录服务以及保障您的账号安全，防范安全风险。如您拒绝授权此类信息，则您将无法使用第三方平台的账号登录我们平台，但不影响我们提供的其他产品和服务的正常使用，您仍可通过手机号码注册、登录嗨数平台。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.3手机号码和验证码匹配结果属于您的个人信息，我们将根据中国法律下的认定规则对该等信息采取相应的保护措施。我们收集该类信息是基于法律法规的相关要求，如您拒绝提供可能导致您无法注册账号并使用嗨数平台的部分功能，请您谨慎考虑后再选择是否提供。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.更换头像</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您使用更换头像功能时，我们将访问您的相机相关权限、获取相册或设备存储系统的读取权限，该等权限均不会默认开启，仅用于向嗨数平台上传头像。如您拒绝提供该权限，仅会使您无法使用该功能，但并不影响您正常使用嗨数平台的其他功能，您也可以随时通过您的设备系统设置页面开启/关闭该权限。相机权限路径通常为：设置-应用设置-应用管理-权限管理-相机；但是不同手机的操作可能不同。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.保存信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您使用移动设备登录我们的产品，若您需要保存达人、商品数据、图片、二维码等信息时，我们可能会获取有关您设备的存储权限，包括保存数据、图片、二维码等。如您拒绝提供该权限，仅会使您无法使用保存信息的功能，但并不影响您正常使用嗨数平台的其他功能，您也可以随时通过您的设备系统设置页面开启/关闭该权限，存储权限路径通常为：设置-应用设置-应用管理-权限管理-读写手机存储；但是不同手机的操作路径可能不同。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.商品橱窗功能</p>

      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您需要添加嗨数APP中的商品橱窗时，我们将会获取您的公开信息（头像、昵称、地区）、您的联盟售卖订单以及佣金信息，用于实现商品橱窗的添加及管理功能。如您拒绝提供本款下的个人信息，仅会使您无法使用该功能，但并不影响您正常使用嗨数平台的其他功能。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.搜索功能</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您使用嗨数平台提供的搜索功能时，我们会收集并使用您查询的关键字信息并记录您在使用嗨数平台时所浏览或要求的信息和内容详情。收集此信息是为了向您提供您所需要的内容和服务并不断完善嗨数的产品和服务。我们在使用上述关键词信息时会对相关信息进行脱敏处理，使其无法单独识别您的个人身份。如果您的搜索关键词信息与您的其他信息有联结并可识别您的个人身份时，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策的规定对其进行处理与保护。如您拒绝提供本款下的信息，仅会使您无法使用该功能，但并不影响您正常使用嗨数平台的其他功能。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.完成支付</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您在嗨数平台上开通付费会员、购买其他增值服务时，需要使用支付功能，在通过第三方完成支付过程中，我们可能会收集您的如下信息：您的第三方支付账号所显示的昵称、头像，但不会收集您的账户信息。上述信息为您开通付费会员或者购买我们提供的其他增值服务所必需的信息，但我们无法仅根据该信息获得您的个人财产信息。同时，我们会收集一些与订单相关的信息，包括：交易金额、付款时间、订单编号、订单状态、支付方式，支付状态。收集此信息是为了帮助您顺利完成交易，保障您的交易安全。如您拒绝提供本款下的信息，仅会使您无法使用该功能，但并不影响您正常使用嗨数平台的其他功能。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.管理我们的产品和服务</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您使用嗨数平台的产品和（或）服务时，我们可能会收集您的个人信息用于管理我们的产品和服务，包括以下具体的权限：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.1IDFA、IMEI：用于获取广告标识，统计广告渠道来源；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.2SIM卡信息、设备MAC地址、AndroidID、MEID：作为用户的唯一标识，以便正常提供统计分析服务；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.3已安装应用列表：当您使用安卓或iOS版本的嗨数APP时，我们为您提供分享的应用服务，因此我们需从您的终端读取已安装应用列表，在您分享时用于判断第三方应用是否已安装在本机；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.4访问剪贴板：自动识别剪贴板内容，识别app内对应的达人、直播等信息，简化用户操作快速进入对应页面</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.5自启动和关联启动</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在您同意本隐私政策后，应用在必要时，会开启自启动或关联启动功能。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以下为可能开启自启动或关联启动的情况：第三方的推送SDK有一定概率会触发。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如您拒绝提供本款下的信息并不会影响您正常使用嗨数平台的其他功能。您可以通过手机系统设置中的“设置-隐私设置-广告设置”来打开或关闭相应的权限，不同手机的操作路径可能不同。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.意见反馈服务</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您向我们进行意见反馈或进行咨询时，为了方便与您联系或帮助您解决问题，我们可能需要收集您的姓名、手机号码、电子邮箱或第三方在线平台账号信息。如您拒绝提供上述信息，我们可能无法向您及时反馈意见处理或咨询结果。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9.推送功能</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您在使用我们的产品和（或）服务时，我们可能使用您的手机号码、电子邮箱、移动设备的推送权限，向您发送电子邮件、短信、新闻或推送通知。如您不希望收到这些信息，可以按照我们的相关提示，在设备上选择关闭通知。如您拒绝提供本款下的信息，仅会使您无法收到我们发出的推送信息，但并不影响您正常使用嗨数平台的其他功能。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.出于其他合理且必要的目的</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您知悉并认可，我们向您提供的功能和服务是不断更新和发展的，如果某一需要收集您的个人信息的产品和（或）服务未能在本隐私政策中予以说明的，或者超出了您所授权的目的及具有直接或合理关联范围的，我们将在收集和使用您的个人信息前，通过更新本隐私政策、页面提示、弹窗、网站公告或其他便于您获知的方式另行向您说明，并为您提供自主选择同意的方式，且在征得您明示同意后收集和使用。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11.征得授权同意的例外</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;根据相关法律法规的规定，以下情形中收集使用您的个人信息无需征得您的同意：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)与国家安全、国防安全直接相关的；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)依法在合理的范围内处理您自行公开的个人信息；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7)根据您的要求签订和履行合同所必需的；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(8)法律法规规定的其他情形。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12.您知悉并认可，嗨数平台可能通过广告或其他方式向您提供链接，使您可以接入第三方服务或网站。嗨数平台会向接入的第三方明确数据安全要求和责任，督促、监督第三方应用运营者加强数据安全管理。但该等第三方可能有其独立的隐私政策和用户协议，我们特此提示您需要仔细阅读其政策。本隐私政策仅适用于嗨数平台提供的服务所收集的信息，并不适用于第三方提供的服务或第三方的信息使用的规则。同时，您亦需谨慎查看第三方在获取您的信息时，是否有明确的提示信息。因您自己的疏忽导致的损失，我们将不承担相应责任。我们对第三方提供的服务不承担任何责任。如您拒绝该等第三方在提供服务时收集为提供服务所必需的个人信息，将可能导致您无法使用相应服务。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;二、我们如何存储和保护您的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.个人信息的存储</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1存储期限：除非依据法律法规或双方约定，我们仅会在实现目的所必需的时间内留存您的相关个人信息。在您注销账号时，我们将根据法律法规删除您的个人信息。对于超出期限的个人信息，我们会立即删除或做匿名化处理（本隐私政策中的“匿名化处理”，是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。）；除根据适用法律需要保留您的个人信息的，我们将不再为任何其他目的使用您的信息。但在下列情况下，我们可能需根据法律法规要求，更改个人信息的存储时间：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)为遵守或我们有理由确信需要遵守适用的法律法规等有关规定；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)为遵守法院判决、裁定或其他法律程序的规定；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)为遵守相关政府机关或法定授权组织的要求；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)为执行相关服务协议或本隐私政策、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。</p>

      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.2存储地域：我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中华人民共和国境内，以下情形除外：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)法律法规有明确规定；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)获得您的明确授权；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)您通过互联网进行跨境交易的个人主动行为。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;针对以上情形，我们会确保依据本隐私政策对您的个人信息提供足够的保护。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.3终止运营：如果发生终止运营等情形，我们将尽合理努力提前30天通知您，并在终止运营后对您的个人信息进行删除或匿名化处理您的个人信息。但是，根据法律法规的要求，我们将对您的订单记录保存三年。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.个人信息的保护</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.1您的个人信息安全对于我们至关重要。我们将严格遵守相关法律法规，采取业内认可的合理可行的措施，保护您的个人信息。防止信息遭到未经授权的访问、披露、使用、修改，避免信息损坏或丢失。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.2安全技术措施：为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不被泄漏、毁损或者丢失，包括但不限于SSL加密传输、信息加密存储、数据中心的访问控制。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.3管理制度：我们成立了专门的数据安全机构并制定了数据安全管理制度以及妥善的数据安全事件预警机制和应急预案。我们对可能接触到您的信息的员工也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。我们会定期开展网络与数据安全和隐私保护相关的培训课程，加强员工对于保护个人信息重要性的认识。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.4在不幸发生数据安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议和对您的补救措施，并立即启动应急预案，力求将损失最小化。我们将及时将事件相关情况以电话、推送通知等方式告知您。我们会尽力保护您的个人信息。我们也请您理解，任何安全措施都无法做到绝对安全。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;三、我们如何共享、转让、公开披露您的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.共享</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1获得您明确同意或授权的共享；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.2根据法律法规的规定、诉讼争议，或行政、司法等有权机关依法的需要；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.转让</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)当进行合并、收购等类似的交易，若涉及到个人信息的转让，我们会要求受让方继续接受本隐私政策的约束，否则，我们将要求受让方重新征求您的授权同意。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.公开披露</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们仅会在以下情况下，且采用符合行业内标准的安全保护措施和手段保护您的个人信息安全的标准前提下，才可能公开披露您的个人信息：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)在您明确同意的披露方式下披露您指定的个人信息；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)如您出现违反中国有关法律、法规或者相关规则的情况，需要向第三方披露。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;四、您如何管理您的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们理解您对个人信息的关注，并尽全力保护您对于您个人信息的查阅、复制、更正、补充、删除、撤回同意授权、注销账户、投诉举报等权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.访问/更正您的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您可以通过本隐私政策第七条联系我们，要求访问、更正您的个人信息，法律法规规定的例外情况除外。您也可以通过以下方式管理您的个人信息：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1账户信息：您可以访问、更正您账户中的个人资料信息（包括但不限于密码、绑定账号）；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.2订单信息：您可以访问订单管理页面查看您的所有状态订单。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.删除您的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您有权通过本隐私政策第七条公示的方式与我们联系，让我们协助您删除您的个人信息。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.撤回同意</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您可以通过在系统中关闭设备权限功能来改变授权范围。但请您理解，特定的业务功能和服务将需要您的信息才能得以完成，当您执行上述操作后，我们可能无法继续为您提供授权对应的产品和（或）服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.注销账户</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您有权注销您的嗨数平台账户，您可以通过访问嗨数App页面上的“设置-关于我们-注销账号”或嗨数网站上“联系客服”页面进行注销。有关注销账号的相关规则和流程，请参见嗨数平台的《用户注销协议》。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.响应您的上述请求</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1为保障安全，您可能需要提供书面请求以实现您的管理权利，或在行使管理权利前以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2如果您在处置您的个人信息时有任何疑问，您可以通过本隐私政策第七条“联系我们”中公示的联系方式与我们沟通解决。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.3在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)与国家安全、国防安全有关的；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)与犯罪侦查、起诉、审判和执行判决有关的；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5)响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6)涉及商业秘密等。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;五、我们如何处理未成年人的个人信息</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.嗨数平台主要面向成年人（原则上18周岁以上为成年人，16周岁以上且以自己的劳动收入为主要生活来源的我们亦视为成年人）。若您是未成年人，我们不建议您使用嗨数平台。如确需使用的，在使用我们的产品和（或）服务前，您应在监护人的陪同下阅读本隐私政策，并应确保已征得您的监护人同意后使用我们的产品和（或）服务并向我们提供您的信息。我们会根据国家相关法律法规的规定着重保护未成年人的个人信息。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.如您的监护人不同意您按照本隐私政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，如我们证实存在上述情况的，我们也有权立即终止向您提供服务。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下收集、使用、共享或披露此信息。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您通过本隐私政策公示的方式及时与我们联系。我们将根据国家相关法律法规及本隐私政策的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;六、本隐私政策的更新和通知</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们可能会适时对本隐私政策进行修订。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在嗨数平台上发布更新通知并在本页面上发布更新后的隐私政策。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过弹窗提示、首页展示等说明隐私政策的具体变更内容）。本隐私政策所指的重大变更包括但不限于：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.个人信息安全影响评估报告表明存在高风险时。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;七、如何联系我们</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如果您对本隐私政策及对您个人信息的处理有任何疑问、意见、建议或投诉，或者希望行使您的权利，可以通过以下方式联系我们：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.您可以通过嗨数App页面上“我的-联系客服”或嗨数网站上显示的联系方式与我们联系；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.您可以通过电话与我们取得联系，联系电话：18072588271。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们收到您的疑问、意见、建议或投诉，并验证您的用户身份后，将在十五个工作日内向您回复。</p>
    </div>
    <div v-else>
      <h1>用户协议</h1>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;本次版本更新时间：2024年05月01日</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;本次版本生效时间：2021年05月04日</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;欢迎使用嗨数平台上的产品！看跨境数据，就上嗨数！</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;本《嗨数用户服务协议》（“本协议”）为用户（下称“用户”或“您”）与浙江台州嗨数科技有限公司（“我们”）之间，关于注册嗨数平台的账号及使用嗨数平台的各项服务等相关事宜所订立的协议。嗨数平台由我们开发、维护，并向您提供数据分析服务。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;如您勾选“同意《嗨数用户服务协议》”并通过注册程序，或以其他任何方式接受或使用嗨数平台的任何服务，即视为您已阅读并同意本协议，自愿接受本协议的所有内容约束。届时您不应以未阅读本协议的内容或者未获得本产品对您问询的解答等理由，主张本协议无效，或要求撤销本协议。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;本协议内容包括协议正文及所有嗨数平台已经发布的或将来可能发布的各类规则，所有规则为本协议不可分割的组成部分。我们有权根据需要不定期地制订、修改本协议或各类规则，并在此公示，不再另行单独通知用户。变更后的协议和规则将在嗨数平台进行公布（以及在嗨数APP、公众号、微信小程序首页推送），在向您公布七天后方可生效。如您不同意相关变更，应当立即停止使用嗨数平台。您继续使用嗨数平台，即表明您接受修订后的协议和规则。如发生有关争议时，以嗨数平台或嗨数服务最新的相关协议和规则为准（但法律另有约定的除外）。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;请您务必再次确认已知悉并完全理解本协议的所有内容。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;一.服务说明</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;1.“嗨数服务”是指我们通过嗨数应用（定义见下文）向您提供的数据分析服务。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;2.“嗨数平台”是指我们向您提供嗨数服务所使用的相关平台统称，包括但不限于如下平台：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“嗨数”网站：https://hishowyun.com/</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“嗨数”APP；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“嗨数数据选品”微信公众号；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“嗨数跨境数据”抖音小程序。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;3.“嗨数应用”指我们在嗨数平台上向用户提供的具有数据分析功能的软件程序，该等软件程序以SaaS方式向您提供使用。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;4.我们所提供的嗨数服务，均仅限于在嗨数平台内使用。任何恶意破解将我们所提供的嗨数服务或嗨数平台行为，包括对嗨数应用开展反向工程的，皆属于违反本协议约定使用嗨数服务的行为。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;二.账号注册</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;1.注册资格</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;用户须具有完全民事权利能力和行为能力的自然人，能够独立承担法律责任。您完成注册程序或以其他我们同意的方式实际使用嗨数服务时，即视为您确认自己具备主体资格，能够独立承担法律责任。您应当拥有中华人民共和国法律认可的完全民事权利能力和完全民事行为能力，或已经获得监护人的书面同意。若因您不具备适当的资格，您应当立即停止使用嗨数平台及相关的任何服务，否则因此产生的一切后果，将由用户自行承担。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;2.注册资料</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;2.1如果因用户的注册信息不真实而引起的问题，以及对问题发生所带来的后果，我们不负任何责任。用户注册信息（如用户名）中不得包含广告信息或违法信息。用户应自行诚信向嗨数平台提供注册资料，其提供的注册资料真实、准确、完整、合法有效。用户注册资料如有变动的，应及时更新其注册资料（您可以在嗨数平台：1）其中，app端可在“我的”界面；2）电脑端官网页面的“个人中心”界面进行更新）。但请您注意，我们通过微信渠道授权获取的您个人信息，将无法变更。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，或者嗨数平台有理由怀疑您提供的注册资料为不合法、不真实、不准确、不详尽的资料，或在注册信息中存在以及向嗨数平台提供的任何信息中含有广告或违法内容，我们有权暂停或终止向您提供服务，冻结或注销您的账号，并拒绝您现在和未来使用嗨数平台之全部或任何功能。若您填写的信息不完整，则您可能无法使用嗨数服务或将在使用过程中受到限制。用户需承担因此引起的相应责任及后果，并且我们保留终止用户使用嗨数平台和/或各项嗨数服务的权利。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;2.2用户在嗨数平台进行浏览等活动时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等个人私密信息的，我们将予以严格保密，除非得到用户的授权或法律另有规定，我们不会向外界披露用户的个人私密信息。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;2.3就非注册用户（即未成为嗨数平台的会员），在您未同意本协议的情况下，可能无法使用全部或者部分嗨数服务。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;三.账户及付费会员</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;1.您注册成功后，即成为嗨数平台的会员，将持有嗨数平台唯一编号的账户信息，您可以根据嗨数平台的规定改变您的密码。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;2.用户可通过各种已有和未来新增的官方渠道进行付费成为付费会员，在使用具体某种方式加入付费会员时，以及其他我们提供的页面中找到另行收费的服务和功能（包括成为特殊会员），用户有权自行选择、购买相关服务。同时，就嗨数应用的使用，我们可能会另行与您签署协议。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;3.您应谨慎合理的保存、使用您的会员名和密码，并对通过您会员账户下实施的行为负责。除非有法律规定或司法裁定，且征得我们的同意，否则，会员名和密码不得以任何方式租用、转让、赠与、继承（与账户相关的财产权益除外）或售卖，或以其他方式允许他人使用。如果嗨数平台发现或者有理由怀疑使用者并非账号初始注册人，则有权在未经通知的情况下，暂停或终止您的注册流程、拒绝向用户提供服务，并有权注销该账号，而无需向该账号使用人承担任何责任，由此带来的包括并不限于用户服务的中断、用户资料和信息等清空的损失由用户自行承担。如果您不同意嗨数平台的决定，请通过拨打我们的客服电话：18072588271与我们联系并申诉。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;4.用户成为付费会员后，如果不接受嗨数平台的产品或服务，您将无法再次取消会员资格。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;5.用户在注册了嗨数平台账号并不意味着有权获得全部嗨数服务，嗨数服务将根据用户的会员资格向用户开放。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;6.用户在账号丢失或遗忘密码后，可遵照嗨数平台登陆页面通过1）手机号获取短信验证码的方式登陆；2）输入手机号点击忘记密码—获取手机验证码的方式重置密码。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;7.如果发现任何非法使用等可能危及您的账户安全的情形时，您应当立即以有效方式通知嗨数平台要求暂停相关服务，并向公安机关报案。您理解嗨数平台对您的请求采取行动需要合理时间，嗨数平台对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;四.用户信息的合理使用</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;1.您同意嗨数平台拥有通过邮件、短信、电话等形式，向在嗨数平台注册用户发送信息等告知嗨数服务相关信息、验证码等消息的权利。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;2.您了解并同意，我们有权应国家司法、行政等主管部门的要求，向其提供您在嗨数平台填写的注册信息和交易记录等必要信息。如您涉嫌侵犯他人知识产权，则我们亦有权在初步判断涉嫌侵权行为存在的情况下，向权利人提供您必要的身份信息。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;3.就我们如何保护用户的个人信息，请见嗨数平台上公示的《嗨数用户隐私政策》。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;五.用户行为规范</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;1.用户行为要求。用户在使用嗨数平台产品或嗨数服务时，应当遵守本协议、相关平台规则及适用法律法规的规定。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;2.用户不得利用嗨数平台或嗨数服务从事违反法律法规、政策以及侵犯他人合法权益的行为，包括但不限于下列行为：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.1利用嗨数平台或嗨数服务发表、传送、传播、储存危害国家安全、国家统一、社会稳定的、煽动民族仇恨、民族歧视、破坏民族团结的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容或者设置含有上述内容的用户名。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.2利用嗨数平台发表、传送、传播、储存侵害他人知识产权、商业机密、肖像权、隐私权等合法权利或其他违反公序良俗</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.3进行任何诸如发布广告、推广信息、销售商品的行为，或者进行任何非法的侵害嗨数服务利益的行为。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.4利用或针对嗨数平台及相关嗨数服务进行任何危害计算机网络安全的行为，包括但不限于：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1)非法侵入嗨数平台网络（包括嗨数平台的系统服务器）、干扰网络正常功能、窃取网络数据等危害网络安全的活动，破坏或规避嗨数平台包含的任何安全技术；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2)提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3)在嗨数平台传播或发送例如病毒、木马、定时炸弹等可能对嗨数平台的运营造成伤害、影响其稳定性的非法软件；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4)未经许可使用嗨数平台的数据（包括但不限于展示、提供或储存的数据），或未经许可进入嗨数平台的服务器；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5)未经允许进入嗨数平台计算机系统并删除、修改、增加存储信息；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6)未经许可，企图探查、扫描、测试嗨数平台系统或网络的弱点或其它实施破坏网络安全的行为；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7)企图干涉、破坏嗨数平台系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8)伪造TCP/IP数据包名称或部分名称（即在TCP/IP协议中的应用层、传输层、网络层和网络访问层中，对任何数据进行伪造）；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9)对嗨数平台及相关服务进行反向工程、反向汇编、编译或者以其他方式尝试发现嗨数平台的源代码；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10)恶意注册嗨数平台账号，包括但不限于频繁、批量注册账号；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11)修改或伪造嗨数平台运行中的指令、数据、数据包，增加、删减、变动软件的功能或运行效果，不得将用于上述用途的软件通过信息网络向公众传播或者运营；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12)对嗨数平台中产生和显示的任何数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，无论出于什么目的，形式包括但不限于使用插件、外挂，盗链、冗余盗取、非法抓取、模拟下载、深度链接、假冒注册等，或非我们经授权的第三方工具/服务接入嗨数平台和相关系统。</p>

      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.5制造虚假身份以误导、欺骗他人。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.6在嗨数平台传播或散布含有受到法律保护的文字、图片、音频、视频、图像、软件或其他资料、文件，或者侵犯任何第三方的版权、商标权、隐私权、姓名权或名誉权的信息或数据，除非您拥有必要的权利或已得到所有必要的许可。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.7使用任何包含有通过侵犯商标、版权、专利、商业机密或任何一方或第三方的其他专有权利的方式利用嗨数平台获得的图像或相片的资料或信息。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.8在未经嗨数平台书面明确授权前提下，出售、出租、出借、散布、转移或转授权嗨数平台和服务或相关的链接，或从使用嗨数平台和服务或嗨数平台和服务的条款中获利，无论以上使用是否为直接经济或金钱收益。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.9违背嗨数平台页面公布的活动规则，包括但不限于发布虚假信息、作弊或通过其他手段进行虚假交易。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.10其他违反法律法规、本协议、嗨数平台的相关规则及侵犯他人合法权益的其他行为。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;3.如果我们有理由认为您的行为违反或可能违反第五条下的约定，或者发现您的用户账户、登录密码和验证码被滥用，或存在赠与、借用、转让或售卖等情形，我们可独立进行判断并处理，且在任何时候有权在不事先通知的情况下终止向您提供嗨数服务，以及有权要求您赔偿我们受到的一切损失。用户需对其名下账户在嗨数平台上的一切行为承担法律责任。用户若在嗨数平台上散布和传播反动、色情或其他违反国家法律的信息，嗨数平台的系统记录有可能作为用户违反法律的证据。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;六.嗨数平台责任限制</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;1.我们将不对嗨数服务作任何明示或暗示的保证，包括但不限于嗨数平台或嗨数服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。嗨数也不对嗨数平台中所涉及的技术或第三方信息的有效性、准确性、正确性、可靠性、稳定性、完整性和及时性作出任何承诺和保证。嗨数平台提供的所有信息、资讯、内容均来自用户自行发布的信息、第三方平台的信息或公开信息，并不代表我们的观点。我们亦不对嗨数服务的有效性、准确性、真实性、合法性、稳定性、完整性和及时性做任何形式的保证，亦不承担任何法律责任（但以法律允许为限）。您应谨慎判断确定相关信息的真实性、合法性和有效性，并注意保留相应的证据以利于维权。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;2.用户了解并同意，我们将不对因下述任一情况而导致用户或第三方的任何损害（无论该等损害是直接的还是间接的）承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其它无形损失的损害赔偿：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使用或未能使用嗨数服务；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三方未经批准地使用您的账户或更改您账户中的任何数据；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过嗨数服务购买或获取任何商品、样品、数据、信息等行为产生的费用及损失；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;用户对嗨数平台或嗨数服务的错误使用；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;任何非因嗨数的原因而引起的与嗨数平台或者嗨数服务有关的其它损失。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;3.用户应理解，互联网技术存在不稳定性，可能导致政府管制、政策限制、病毒入侵、黑客攻击、服务器系统崩溃或者其他我们现有技术无法解决的风险发生。因以上原因可能导致嗨数平台或者嗨数服务的中断，因此造成的损失由用户自行承担责任。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;4.您同意，若您发现嗨数平台任何的内容或不符合法律规定，或不符合本协议规定的，您有义务按照本协议第七条规定的投诉渠道及时通知我们。如果您发现您的个人信息被盗用或者您的任何权利被侵害，请将此情况及时告知我们并同时提供如下信息和材料：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a)侵犯您权利的信息的相关信息，编号或其他可以找到该信息的细节；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b)您是所述权利的合法拥有者的权利证明；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c)您的联系方式，包括联系人姓名，地址，电话号码和电子邮件；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d)您的身份证复印件、营业执照等其他相关资料。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;经审查得到证实的，我们将及时删除相关信息。我们仅接受邮寄、电子邮件或传真方式的书面侵权通知。情况紧急的，您可以通过客服电话先行告知，我们会视情况采取相应措施。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;5.您知悉并确认，嗨数平台可能包含第三方信息、产品或服务。您在嗨数平台上使用第三方的信息、产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。您因使用第三方信息、产品或服务所产生的纠纷，我们将不承担任何责任。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;6.您同意，在本协议因任何原因终止后，我们仍享有下列权利：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a)继续保存您未及时删除的注册信息及您使用嗨数平台期间发布的所有信息（以法律允许为限）直至法律规定的记录保存期满；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b)您在使用嗨数平台期间存在违法行为或违反本协议和/或相关规则的行为的，我们仍可依据本协议向您主张权利、追究责任。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;七.知识产权声明</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;1.“嗨数”为我们的注册商标，由金华事了了网络科技有限公司所有，受法律保护，未经许可任何人不得擅自使用。凡擅自使用该等商标的，我们将依法追究其相关法律责任。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;2.嗨数平台所包含的资料信息（包括但不限于编码、文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是我们或其内容提供者的专属财产，受相应法律的保护。嗨数平台上所有内容的汇编是我们的排他性财产，受适用法律的保护。嗨数平台及嗨数平台的所有代码、软件都是我们或其关联公司或其软件供应商的财产，受适用法律的保护。我们给予您一项普通的、在中国范围内的、非商用的、不可分的、不可转让的许可，以使用嗨数平台及嗨数平台上的服务。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;3.未经我们的事先明确书面许可，任何第三方不得为任何非私人或商业目的获取或使用嗨数平台的任何部分或通过嗨数平台可直接或间接获得的任何内容、服务或资料。任何经授权的复制、发布、传播、转让嗨数平台上的信息内容及其任何部分的都必须包括此版权声明。任何用户违反本协议的规定，以任何方式对嗨数平台的任何部分进行发表、复制、转载、更改、引用、链接、下载或以其他方式进行使用，允许第三方进行前述使用或向任何其他第三方提供获取嗨数平台任何内容的渠道，则嗨数平台有权立即冻结/注销其账号、停止向其提供任何服务、要求该用户及第三方停止使用前述内容并要求该用户赔偿一切损失。该用户必须按照我们的要求，归还或销毁（或促使第三方归还或销毁）使用嗨数平台任何部分的内容所创建的资料的任何副本。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;4.用户一旦接受本协议，即表明您愿意将您在任何时间段内在嗨数平台发表的任何形式的信息内容（包括但不限于用户评价信息内容）中的财产性权利等，如著作权中财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），向我们授予一项非排他的、全球范围的、可分许可的、不可撤销的、免费的许可（包括但不限于复制权、翻译权、汇编权、信息网络传播权、改编权及制作衍生品、表演和展示的权利等），以使得我们可以制作、出版、发行、复制、分发、出租、展示、展览、传播、表演、放映、广播和以其他方式使用该等信息内容。以法律允许为限，用户同意我们有权就任何主体侵权而单独提起诉讼。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;5.用户通过嗨数平台发布的信息、文字、图片等资料均由嗨数用户提供，其真实性、准确性和合法性由信息发布人负责。我们不提供任何保证，并不承担任何法律责任。如果以上资料侵犯了第三方的知识产权或其他权利，责任由信息发布者本人承担，我们将依照现有法律的规定进行处理。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;6.除法律另有强制性规定外，未经我们明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、传播、展示、镜像、上载、下载、转载、引用、链接、抓取或以其他方式使用嗨数平台的信息内容，否则，我们有权追究其法律责任。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;7.未经我们事先同意，用户不得转载、发布、传播、对外提供嗨数平台以下内容：</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a)嗨数平台内发布的所有信息；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b)已作出不得转载或未经许可不得转载声明的内容；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c)嗨数平台中特有的图形、标志、页面风格、编排方式、程序等；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d)嗨数平台中必须具有特别授权或具有注册用户资格方可知晓的内容；</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(e)其他法律不允许或嗨数平台认为不适合转载、发布、传播、对外提供的内容。</p>

      <p>&nbsp;&nbsp;&nbsp;&nbsp;八.法律适用、管辖与其他</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;1.本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律，如就嗨数平台所提供的服务或本协议内容发生争议，我们愿意与您友好协商解决；协商不成的，您同意纠纷由浙江省金华市婺城区人民法院管辖。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;2.您同意利用互联网信息技术以数据电文的形式订立本协议并完全认可其效力。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;3.本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
    </div>
  </div>

</template>
<script>
export default {
  name: 'index',
  props: {},
  components: {},
  data() {
    return {
      type: 1,
    }
  },
  computed: {},
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        this.type = query && query.type ? query.type : 1;
        if(this.type == 2) {
          document.title = ' 嗨数云数据 | 用户协议'
        }
        console.log('type', this.type)
      },
      immediate: true,
    },
  },
  created() {
  },
  mounted() { },
}
</script>

<style lang="less" scoped>
.perssion {
  padding: 20px;
  font-size: 16px;
  h1 {
    text-align: center;
  }
}
</style>

